nav {
    padding-top: 30px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 50px;
    background-color: #282c34;
}

/*-> Nav Links <-*/
a.button--link {
    color: white;
    font-size: 16px;
    text-decoration: none;
    font-weight: lighter;
}

a.button--link.active {
    color: orange;
}

a.button--link:after {
    content: "\00B7";
    padding-left: 10px;
    padding-right: 10px;
    color: orange;
}

a.button--link:last-child:after {
    content: "";
}
